import React from "react";
import loadable from "@loadable/component";

const Challenge = loadable(() => import("@organisms/Challenge"));
const IntentionProfile = loadable(() => import("@organisms/IntentionProfile"));

const IntentionProfileContainer = ({ intro, intentions, prologue }) => {
  return (
    <>
      <div className="relative z-10 mb-12 md:ml-14" />
      <Challenge {...intro} twoColumns lessBottomSpacing />

      {intentions.map((intention, i) => (
        <IntentionProfile {...intention} align={i % 2 ? "right" : "left"} />
      ))}

      <div className="relative z-10 mb-12 md:ml-14" />
      <Challenge {...prologue} twoColumns />
    </>
  );
};

export default IntentionProfileContainer;
